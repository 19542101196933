<template>
  <div class="">
    <div class="flex row header-toogle">
      <p class="col-6">Notificação</p>
      <p class="col-3">E-mail</p>
      <p class="col-3">Mural</p>
    </div>
    
    <div class="flex row items-center" v-for="(item, index) in listOptionConfig" :key="index">
      <div class="flex full-width" v-if="item.userType == userData || item.userType == null">
        <hr v-if="index != 0" />
        <div class="row full-width">
          <p class="col-6 title-option">{{item.name}}</p>
          <div class="col-3"><q-toggle color="red" v-model="item.emailvalue"/></div>
          <div class="col-3"><q-toggle color="green" v-model="item.muralvalue"/></div>
        </div>
        <div class="flex column child-option">
          <div class="flex row items-center justify-center" v-for="(child, index) in item.child" :key="index">
            <p class="col-6 child-title">{{child.name}}</p>
            <div class="col-3 email-option"><q-toggle color="red" v-model="child.emailvalue"/></div>
            <div class="col-3 mural-option"><q-toggle color="green" v-model="child.muralvalue"/></div>
          </div>
      </div>
      </div>
      
    </div>
  </div>
</template>
<script>
import {useStore} from 'vuex'
import { ref, onMounted } from "vue";

export default {
  name: "ConfigurationToogles",
  setup() {
    const store = useStore();
    let userData = ref('');
    let listOptionConfig = ref([
      {
        'name': 'Sistema',
        'emailvalue':true,
        'muralvalue':true
      },
      {
        'name': 'Conhecimento',
        'emailvalue':true,
        'muralvalue':true,
        'child':[
          {
            'name': 'Avaliações',
            'emailvalue':true,
            'muralvalue':true
          },
          {
            'name': 'Quiz',
            'emailvalue':true,
            'muralvalue':true
          },
          {
            'name': 'Novo conteúdo',
            'emailvalue':true,
            'muralvalue':true
          },
          {
            'name': 'Fórum',
            'emailvalue':true,
            'muralvalue':true
          },
        ]
      },
      {
        'name': 'Loja e conquistas',
        'userType': 4,
        'emailvalue':true,
        'muralvalue':true,
        'child':[
          {
            'name': 'Recompensas',
            'emailvalue':true,
            'muralvalue':true
          },
          {
            'name': 'Avanço de nível',
            'emailvalue':true,
            'muralvalue':true
          },
          {
            'name': 'Moedas',
            'emailvalue':true,
            'muralvalue':true
          },
        ]
      },
      {
        'name': 'Mensagens',
        'emailvalue':true,
        'muralvalue':true
      },
    ]);

    function _getUserValues(){
     
      userData.value = store.state.userdata.user_group_id;
    } 

    onMounted(() => {
      _getUserValues()
    });

    return {
      listOptionConfig,
      userData
    };
  },
};
</script>

<style lang="scss" scoped>

.header-toogle{
  margin-left: 5%;
  p{
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    color: #686868;
  }
}
.title-option {
  font-style: normal;
  font-weight: 500;
  font-size: 0.87rem;
  color: #2f2f2f;
}

.child-option{
  margin-left: 7%;
  width: 86%;

  .child-title{
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    margin: 0;
    color: #2f2f2f;
  }
  .email-option{
  }
  .mural-option{
    padding-left: 4%;
  }
}

hr {
  width: 100%;
  height: 1px;
  min-height: 1px;
  background: #e5e5e5;
  border: none;
  margin-bottom: 0.5rem;
}
</style>
