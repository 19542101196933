<template>
  <q-carousel
    v-model="slide"
    transition-prev="jump-right"
    transition-next="jump-left"
    swipeable
    animated
    control-color="default-pink"
    navigation
    padding
    arrows
    class="text-black rounded-borders full-width"
  >
    <q-carousel-slide
      v-if="post.link_live != null && post.link_live != 'null'"
      :name="1"
      class="column no-wrap flex-center"
    >
      <div class="container-posts q-mt-md text-center">
        <iframe
          width="560"
          height="315"
          :src="getUrlLive(post.link_live)"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </q-carousel-slide>
    <q-carousel-slide :name="validateNameForSurvey()" v-if="post.is_survey"
      ><AnswersSurvey
        :postId="post.postId"
        :yourQuestion="post.your_question"
        :duration="post.survey_duration"
      />
    </q-carousel-slide>
    <q-carousel-slide
      :name="validateNameForFiles(index)"
      v-for="(file, index) in post.files"
      :key="index"
      class="column no-wrap flex-center"
    >
      <div class="container-posts q-mt-md text-center">
        <q-img
          v-if="file.type_file == 'image'"
          :src="url_aws_bucket + file.name"
          fit="contain"
          height="auto"
          width="550px"
          spinner-color="white"
        />

        <vue3-video-player
          v-else-if="file.type_file == 'video'"
          :src="url_aws_bucket + file.name"
          preload="auto"
        >
        </vue3-video-player>

        <iframe
          v-else-if="
            file.type_file == 'x-zip-compressed' || file.name.includes('scorm')
          "
          :src="url_aws_bucket + file.name"
          frameborder="0"
          seamless="seamless"
          sandbox="allow-scripts allow-same-origin"
          allowfullscreen
          style="width: 600px; height: 500px"
          spinner-color="white"
        />
        <div
          v-else-if="file.type_file == 'application'"
          class="flex items-center q-pa-sm"
        >
          <a
            :href="url_aws_bucket + file.name"
            target="_blank"
            class="text-bold text-default-pink q-pa-xs q-mx-xs"
          >
            Faça o download deste conteúdo
          </a>
          <q-btn
            class="q-ml-sm"
            target="_blank"
            round
            size="sm"
            :href="url_aws_bucket + file.name"
            color="default-pink"
            icon="download"
          />
        </div>

        <div
          v-if="
            file.type_file != 'video' &&
            (file.type_file != 'application' || file.name.includes('scorm'))
          "
          class="col-12 row flex q-pr-lg q-pb-sm absolute-bottom justify-end"
        >
          <q-btn
            round
            size="sm"
            :href="url_aws_bucket + file.name"
            target="_blank"
            color="default-pink"
            icon="fullscreen"
            style="z-index: 1"
          />
        </div>
      </div>
    </q-carousel-slide>
  </q-carousel>
</template>

<script>
//----VUEJS
import { ref, computed } from "vue";

import AnswersSurvey from "@/components/Feed/post/Survey/AnswersSurvey.vue";

//----UTILS
import { ENV } from "@/utils/env";

export default {
  name: "CarouselPosts",
  components: { AnswersSurvey },
  props: {
    post: Array,
  },
  setup(props) {
    //----VARIABLES
    let slide = ref(1);
    let url_aws_bucket = ENV.URL_AWS_BUCKET;

    function getUrlLive(url) {
      if (url.includes("https://youtu.be/")) {
        url = url.replace(
          "https://youtu.be/",
          "https://www.youtube.com/embed/"
        );
      }
      if (url.includes("https://www.youtube.com/watch?v=")) {
        url = url.replace(
          "https://www.youtube.com/watch?v=",
          "https://www.youtube.com/embed/"
        );
      }

      return url;
    }

    function validateNameForSurvey() {
      const { link_live } = props.post;
      const hasLive = link_live != null && link_live != "null";
      const isSurvey = true;
      let value;

      if (hasLive && isSurvey) {
        value = 2;
      } else if (!hasLive && isSurvey) {
        value = 1;
      }
      return value;
    }

    function validateNameForFiles(index) {
      const { link_live, files, is_survey } = props.post;
      const hasLive = link_live != null && link_live != "null";
      const hasFiles = files.length > 0;

      let value;

      if (hasFiles && !hasLive && !is_survey) {
        value = index + 1;
      } else if (hasFiles && hasLive && is_survey) {
        value = index + 3;
      } else if (hasFiles && hasLive && !is_survey) {
        value = index + 2;
      } else if (hasFiles && !hasLive && is_survey) {
        value = index + 2;
      }

      return value;
    }

    return {
      //----VARIABLES
      slide,
      url_aws_bucket,
      getUrlLive,
      /* Function */
      validateNameForSurvey,
      validateNameForFiles,
    };
  },
};
</script>

<style lang="scss" scoped>
.container-posts {
  //overflow: scroll;
  align-items: center;
  display: flex;
  justify-content: center;
  // max-height: 550px;
}
</style>
