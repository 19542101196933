<template>
  <div class="profile-user">
    <div class="avatar-notification clickble" @click="getUser">
      <q-icon v-if="avatarUser == null" name="account_circle" size="35px" />

      <q-img
        v-else
        width="100%"
        height="100%"
        :src="url_aws_bucket + avatarUser"
        spinner-color="white"
      >
        <!-- user.avatar_location -->
        <template v-slot:error>
          <q-icon class="avatar" name="account_circle" size="35px" />
        </template>
      </q-img>
    </div>

    <div class="card-user-profile" v-if="showDetailCard">
      <header>
        <q-btn
          c
          flat
          round
          class="q-pa-sm"
          color="white"
          icon="close"
          @click="
            () => {
              showDetailCard = !showDetailCard;
            }
          "
        />
      </header>
      <div class="mood z-max" v-if="showDetailCard && canUserSee">
        <q-img
          class="mood-icon"
          :src="url_aws_bucket + user?.emoji_path"
          spinner-color="primary"
          spinner-size="82px"
          style="width: 40px; height: 40px; border-radius: 50%"
        >
          <q-tooltip
            anchor="center right"
            self="center left"
            :offset="[10, 10]"
          >
            {{ user.title }}
          </q-tooltip>
        </q-img>
      </div>
      <main>
        <div class="postion-main avatar-notification">
          <q-img
            width="100%"
            height="100%"
            :src="url_aws_bucket + user.avatar_location"
            spinner-color="white"
          >
            <template v-slot:error>
              <q-icon class="avatar" name="account_circle" size="75px" />
            </template>
          </q-img>
        </div>

        <h5>{{ user.first_name + " " + user.last_name }} {{ nick_name }}</h5>
        <small>{{ user.role_name }}</small>
        <div class="list-groups">
          <div class="group" v-for="item in user.groups" :key="item">
            <small>{{ item.name_group }}</small>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
//----SERVICES
import UserServices from "@/services/UserServices";

//----UTILS
import { ENV } from "@/utils/env";
import CanUserAccessClass from "@/utils/CanUserAccessClass";

//-----VUE
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "UserPhoto",
  props: {
    idUser: Number,
    avatarUser: {
      type: String,
      default: null,
    },
  },

  setup(props) {
    //----SERVICES
    const userServices = new UserServices();

    //----CONSTATS
    const url_aws_bucket = ENV.URL_AWS_BUCKET;
    const canUserSee = ref(false);
    const store = new useStore();
    const canUserAcess = new CanUserAccessClass();

    //----VARIABLES
    let showDetailCard = ref(false);
    let user = ref({
      avatar_location: props.avatar_user,
    });
    onMounted(() => {
      // getUser();
      ValidateActionsPage();
    });

    const userValidation = computed(() => {
      return store.state.userdata.id === props.idUser;
    });

    async function ValidateActionsPage() {
      if (
        canUserAcess.canUserAccess("humor-view-other-user", false) ||
        userValidation.value
      ) {
        canUserSee.value = true;
      }
    }

    async function getUser() {
      const result = await userServices.getUserbyId(props.idUser);
      showDetailCard.value = !showDetailCard.value;
      user.value = result.data;
    }

    const nick_name = computed(() => {
      if (user.value.nick_name != null) return `(${user.value.nick_name})`;
    });

    return {
      //----CONSTATS
      url_aws_bucket,
      canUserSee,
      nick_name,
      //----VARIABLES
      showDetailCard,
      user,
      getUser,
    };
  },
};
</script>

<style lang="scss" scoped>
.mood {
  position: absolute;
}
.mood-icon {
  position: absolute;
  right: 60px;
  top: 5px;
}

.profile-user {
  position: relative;
  margin: 0 2%;
}
.avatar-notification {
  width: 35px;
  height: 35px;
  overflow: hidden;
  border-radius: 35px;
  background: #f1f1f1;
}
.clickble {
  cursor: pointer;
}

.card-user-profile {
  position: absolute;
  top: 0;
  left: 115%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;

  border-radius: 16px;
  min-width: 180px;
  max-width: 290px;
  height: 180px;
  z-index: 1;

  background: var(--q-primary, #fe4e64);

  main {
    width: 100%;
    height: 70%;
    position: relative;
    padding: 44px 8px 8px 10px;
    overflow: initial;
    background: #f1f1f1;
    border-radius: 0 0 16px 16px;
    box-shadow: 3px 10px 15px rgb(0 0 0 / 20%);
    .postion-main {
      position: absolute;
      bottom: 70%;
      left: 10%;
      border-radius: 100%;
      border: 4px solid #f1f1f1;
      width: 85px;
      height: 85px;
    }

    h5 {
      font-size: 12px;
      line-height: 12px;
      font-weight: 800;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
    }
    small {
      color: #000000a1;
    }

    .list-groups {
      overflow-x: auto;
      background: none;
      display: flex;
      align-items: center;
      height: 38px;
      .group {
        min-width: fit-content;
        width: fit-content;
        height: fit-content;
        display: flex;

        align-items: center;
        justify-content: center;

        padding: 5px;
        border-radius: 50px;

        background: var(--q-primary, #fe4e64);
        box-shadow: 3px 4px 9px rgb(0 0 0 / 20%);
        small {
          color: #fff;
        }
      }
    }
  }
}
.group + .group {
  margin-left: 6px;
}
</style>
