<template>
  <q-dialog v-model="isShowDialog" persistent>
    <q-card>
      <q-card-section class="column items-left dialog-main">
        <div
          class="row q-my-md"
          v-show="fileType == 'x-zip-compressed' || fileType == 'zip'"
        >
          <q-input
            type="text"
            label="Título do arquivo"
            v-model="scormName"
            :rules="[
              (val) =>
                !!scormName ||
                'O nome do conteúdo interativo não pode ser vazio',
            ]"
            class="col-12"
            @update:model-value="
              () => {
                $emit('gameName', scormName);
              }
            "
          />
        </div>
        <div class="column items-center">
          <h6>Adicionar um anexo</h6>
          <q-uploader
            url="http://localhost:8080/upload"
            color="default-pink"
            square
            flat
            bordered
            hide-upload-btn
            :filter="
              (files) => {
                $emit('selectMedia', files);
                return checkFileType(files, fileType);
              }
            "
            @rejected="onRejected"
            style="max-width: 300px"
          />
        </div>

        <div
          v-show="fileType == 'x-zip-compressed' || fileType == 'zip'"
          class="column items-center"
        >
          <h6 class="q-mt-md">Adicionar uma imagem ao conteúdo</h6>
          <q-uploader
            :filter="
              (files) => {
                $emit('gameImage', files);
                return checkFileType(files, optionalImage);
              }
            "
            hide-upload-btn
            @rejected="onRejected"
            color="default-pink"
            flat
            square
            bordered
            style="max-width: 300px"
          />
        </div>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn
          text-color="default-pink"
          dense
          flat
          label="Cancelar"
          color="primary"
          @click="$emit('closeDialog')"
          v-close-popup
        />
        <q-btn
          color="default-pink"
          text-color="white"
          no-caps
          unelevated
          dense
          label="Salvar"
          @click="$emit('closeDialog')"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
//----VUE
import { ref, watch } from "vue";

//----QUASAR
import { useQuasar } from "quasar";

export default {
  name: "DialogMedia",
  props: {
    showDialog: Boolean,
    fileType: String,
    optionalImage: {
      type: String,
    },
  },
  emits: ["selectMedia", "closeDialog", "gameImage", "gameName"],
  setup(props) {
    //----CONSTANTE
    const $q = useQuasar();
    const fileTypeScorm = ref(["x-zip-compressed", "zip"]);
    const scormName = ref("");
    const isShowDialog = ref(props.showDialog);

    watch(
      () => props.showDialog,
      (newState) => {
        isShowDialog.value = newState;
      }
    );

    function onRejected() {
      $q.notify({
        type: "negative",
        message: `O arquivo não pode ser aceito`,
      });
    }

    function checkFileType(files, typeSelected) {
      if (files.length > 1) return;

      return files.filter((file) => {
        let type = file.type.split("/");
        if (typeSelected == "x-zip-compressed" || typeSelected == "zip") {
          for (let index = 0; index < type.length; index++) {
            if (fileTypeScorm.value.includes(type[index])) {
              return file;
            }
          }
        } else {
          if (
            type[0] === typeSelected &&
            !fileTypeScorm.value.includes(type[1])
          ) {
            return file;
          }
        }
      });
    }

    return {
      //----FUNCTIONS
      onRejected,
      checkFileType,
      scormName,
      isShowDialog
    };
  },
};
</script>
