<template>
  <div class="body-coment">
    <h5>
      {{ name }} <small class="date-coment"> {{ dateComment }}</small>
    </h5>

    <div class="flex row justify-between" v-if="showEditionComent">
      <q-input
        class="flex col-10 spaceNameInput"
        rounded
        outlined
        borderless
        bg-color="white"
        color="default-pink"
        dense
        v-model="inputEditValue"
        @click.stop
        ref="spaceTypeNameRef"
        @keydown.enter="$emit('edit', inputEditValue)"
        @keydown.space="(e) => spacePressed(e)"
      >
        <template v-slot:append v-if="showEditionComent">
          <div class="box-send-icon">
            <q-img
              class="send-message-chat"
              @click="$emit('edit', inputEditValue)"
              src="@/assets/icons/location_arrow.svg"
            />
          </div>
        </template>
      </q-input>
      <q-btn size="12px" flat dense round color="grey-8" icon="close" @click="() => showEditionComent = false">
      </q-btn>
    </div>

    <div class="flex row justify-between" v-else>
      <p>
        {{ coment }}
      </p>
      <q-btn size="12px" flat dense round color="grey-8" icon="more_vert"  v-if="removeCommentPermission || validateActionsPage()">
        <q-menu>
          <q-list style="min-width: 100px">
            <q-item clickable v-close-popup @click="$emit('disabled')" v-if="removeCommentPermission || validateActionsPage()">
              <q-item-section avatar>
                <q-icon
                  size="sm"
                  color="default-pink"
                  class="material-icons-outlined"
                  name="delete"
                />
              </q-item-section>
              <q-item-section> Remover </q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="editComent()" v-if="validateActionsPage()">
              <q-item-section avatar>
                <q-icon
                  size="sm"
                  color="default-pink"
                  class="material-icons-outlined"
                  name="edit"
                />
              </q-item-section>
              <q-item-section> Editar </q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-btn>
    </div>

    <div class="actions-coment row">
      <slot />
    </div>
  </div>
</template>

<script>
import RoundedBtn from "@/components/shared/buttons/RoundedBtn.vue";

import CanUserAccessClass from "@/utils/CanUserAccessClass";

import { date } from "quasar";

import { useStore } from "vuex";
import { onMounted, ref } from "vue";

export default {
  name: "ComentUserCard",
  components: {
    RoundedBtn,
  },
  props: {
    name: String,
    coment: String,
    countLikes: Number,
    countComents: Number,
    idUser: Number,
    date_at: String,
  },
  emits: ["disabled", "edit"],
  setup(props) {
    const store = useStore();
    const canUserAccessClass = new CanUserAccessClass();

    let dateComment = date.formatDate(props.date_at, "DD/MM/YYYY H:mm");

    let showEditionComent = ref(false);
    let editCommentPermission = ref(false);
    let removeCommentPermission = ref(true);

    let inputEditValue = ref(`${props.coment}`);
    let userData = ref(store.state.userdata);

    function editComent() {
      showEditionComent.value = !showEditionComent.value;
    }

    function spacePressed(e) {
      if (e.target.selectionStart ===  inputEditValue.value.length) {
        inputEditValue.value =  inputEditValue.value + " ";
      } else {
        let firstPart =  inputEditValue.value.substring(0, e.target.selectionStart);
        let selection = e.target.selectionStart;
        let secondPart =  inputEditValue.value.substring(
          e.target.selectionStart,
           inputEditValue.value.length
        );

        inputEditValue.value = firstPart + " " + secondPart;
        let el = document.querySelector(".spaceNameInput");
        setTimeout(function () {
          el.setSelectionRange(selection + 1, selection + 1);
          el.focus();
        }, 0);
      }
    }


    function validateActionsPage() {
      if(userData.value.id == props.idUser){
        return true;
      }
      return false;
    }

    async function _removeCommentPage() {
      
      if (!canUserAccessClass.canUserAccess("post-edit-comment", false)) {
        removeCommentPermission.value = false;
        return;
      }
    }

    onMounted(()=>{
      //validateActionsPage()
      _removeCommentPage()
    });

    return {
      dateComment,
      showEditionComent,
      inputEditValue,
      editCommentPermission,
      removeCommentPermission,
      userData,
      editComent,
      spacePressed,
      validateActionsPage
    };
  },
};
</script>

<style lang="scss" scoped>
.body-coment {
  background: rgba(0, 0, 0, 0.03);
  padding: 2%;
  border-radius: 8px;
  flex: 1;
  position: relative;

  h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #000000;
  }
  p {
    padding: 0.5% 0;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #00000073;
    margin: 0;
  }
  .date-coment {
    font-style: normal;
    font-weight: 500;
    font-size: 0.62rem;
    line-height: 12px;
    color: #0000004d;
    float: right;
  }
  .actions-coment {
    position: absolute;
    width: 100%;
    bottom: 0%;
    left: 1%;
    height: 20px;
  }
}
.box-send-icon {
  width: 25px;
  height: 25px;
  position: relative;
}
.send-message-chat {
  cursor: pointer;
  width: 100%;
  height: auto;
  position: absolute;
}
</style>
