<template>
  <div class="flex column activity-card">
    <div class="flex row items-center justify-between header">
      <h6 class="text-default-pink">Atividades 02 de Março 2020</h6>
      <q-icon name="close" color="default-pink"/>
    </div>
    <div class="main">
      <div class="task-card" v-for="task in activitys" :key="task.name">
        <h5>{{ task.name }}</h5>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ActivityDayCard',
  props: {
    activitys: Array
  }
}
</script>

<style lang="scss" scoped>
.activity-card {
  width: 100%;
  background: #FDFDFD;
  box-shadow: 0px 0.25px 1px rgba(0, 0, 0, 0.039), 0px 0.85px 3px rgba(0, 0, 0, 0.19);
  border-radius: 15px;
  padding: 1rem;
  position: relative;

  &::before {
    content: '';
    width: 100%;
    height: 16px;
    background: var(--q-primary, #fe4e64);
    border-radius: 15px 15px 0 0;
    position: absolute;
    left: 0;
    top: 0;
  }
}

.header {
  h6 {
    font-style: normal;
    font-weight: 600;
    font-size: 0.8rem;

  }
}

.main {
  min-height: 108px;
  max-height: 130px;
  overflow: auto;
  display: flex;
  flex-direction: column;

  .task-card {
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 8px;
    padding: .5rem;
    margin: 2%;
    position: relative;


    &::before {
      content: '';
      height: 70%;
      width: 3px;
      background: #85CCBC;
      border-radius: 0px 2px 2px 0px;
      position: absolute;
      left: 0;
      top: 15%;
    }

    h5 {
      font-style: normal;
      font-weight: 500;
      font-size: 0.75rem;
      color: #000000;
    }
  }
}

</style>
