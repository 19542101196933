<template>
  <div class="full-width q-pa-md">
    <q-carousel
      animated
      v-model="slide"
      arrows
      navigation
      class="text-white shadow-1 rounded-borders"
      control-color="default-pink"
      ref="carousel"
    >
      <q-carousel-slide
        :name="index + 1"
        v-for="(file, index) in oldFiles"
        :key="index"
        class="carousel-items"
      >
      <q-icon
          class="remove-card col-3"
          name="delete_outline"
          size="md"
          color="default-pink"
          @click="
            () => {
              $emit('removeOldItem', file), $refs.carousel.previous();
            }
          "
        />
        <div class="container-posts q-mt-md text-center">
          <q-img
            v-if="file.type_file == 'image'"
            :src="url_aws_bucket + file.name"
            fit="cover"
            height="auto"
            width="90%"
            spinner-color="white"
          />

          <vue3-video-player
            v-else-if="file.type_file == 'video'"
            :src="url_aws_bucket + file.name"
          >
          </vue3-video-player>
          <div
            v-else-if="file.type_file == 'application'"
            class="flex items-center q-pa-sm"
          >
            <a
              :href="url_aws_bucket + file.name"
              target="_blank"
              class="text-bold text-default-pink q-pa-xs q-mx-xs"
            >
              Faça o download deste conteúdo
            </a>
            <q-btn
              class="q-ml-sm"
              target="_blank"
              round
              size="sm"
              :href="url_aws_bucket + file.name"
              color="default-pink"
              icon="download"
            />
          </div>

          <iframe
            v-else-if="file.type_file == 'x-zip-compressed'"
            :src="url_aws_bucket + file.name"
            frameborder="0"
            seamless="seamless"
            sandbox="allow-scripts allow-same-origin"
            allowfullscreen
            style="width: 100%; height: 300px"
            spinner-color="white"
          />

          <div
            v-if="file.type_file != 'video' && file.type_file != 'application'"
            class="col-12 row flex q-pr-lg q-pb-sm absolute-bottom justify-end"
          >
            <q-btn
              round
              size="sm"
              :href="url_aws_bucket + file.name"
              target="_blank"
              color="default-pink"
              icon="fullscreen"
              style="z-index: 1"
            />
          </div>
        </div>
      </q-carousel-slide>

      <q-carousel-slide
        :name="index + oldFiles.length + 1"
        v-for="(file, index) in files"
        :key="index"
        class="carousel-items"
      >
        <q-icon
          class="remove-card col-3"
          name="delete_outline"
          size="md"
          color="default-pink"
          @click="
            () => {
              $emit('removeItem', index), $refs.carousel.previous();
            }
          "
        />
        <div class="content-carosel q-mt-md text-center">
          
          <q-img
            v-if="file.type.includes('image')"
            :src="getFile(file)"
            fit="cover"
            height="auto"
            width="90%"
            spinner-color="white"
          />

          <vue3-video-player
            v-else-if="file.type.includes('video')"
            :src="getFile(file)"
          >
          </vue3-video-player>
          <div
            v-else-if="file.type.includes('application')"
            class="flex items-center q-pa-sm"
          >
            <a
              :href="getFile(file)"
              target="_blank"
              class="text-bold text-default-pink q-pa-xs q-mx-xs"
            >
              Faça o download deste conteúdo
            </a>
            <q-btn
              class="q-ml-sm"
              target="_blank"
              round
              size="sm"
              :href="getFile(file)"
              color="default-pink"
              icon="download"
            />
          </div>

          <iframe
            v-else-if="file.type.includes('zip')"
            :src="getFile(file)"
            frameborder="0"
            seamless="seamless"
            sandbox="allow-scripts allow-same-origin"
            allowfullscreen
            style="width: 100%; height: 300px"
            spinner-color="white"
          />

          <div
            v-else
            class="col-12 row flex q-pr-lg q-pb-sm absolute-bottom justify-end"
          >
            <q-btn
              round
              size="sm"
              :href="getFile(file)"
              target="_blank"
              color="default-pink"
              icon="visibility"
            />
          </div>
        </div>
      </q-carousel-slide>
    </q-carousel>
  </div>
</template>

<script>
//----VUE
import { ref } from "vue";

//----UTILS
import { ENV  } from "@/utils/env";

export default {
  name: "CaroselFiles",

  props: {
    files: Array,
    oldFiles: Array,
  },
  emits: ["removeItem", "removeOldItem"],
  setup(props) {
    //----VARIABLES
    let slide = ref(1);
    let url_aws_bucket =ENV.URL_AWS_BUCKET;

    function getFile(file) {
      return URL.createObjectURL(file);
    }

    return {
      slide,
      url_aws_bucket,

      //-----FUNCTIONS
      getFile,
    };
  },
};
</script>

<style lang="scss" scoped>
.card-item {
  height: 90%;
}

.remove-card {
  cursor: pointer;
}
.carousel-items{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content-carosel {
  width:90%;
  max-height:  325px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  display: flex;
}

.container-posts{
  width:90%;
  // max-height:  325px;
  overflow: hidden;
 
  display: flex;
  justify-content: center;
   align-items: center;
  
}

p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  /* Light/Grey5 */
  color: #2f2f2f;
  margin: 0;
  max-width: 150px;
  overflow: hidden;
}
</style>
