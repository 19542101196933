import http from '../http-common'

export default class NotificationServices {
    async getNotifications(data) {
        return http.get("v1/notification");
    }

    async setVisualizedNotification(notificationId) {
        return http.put(`v1/notification/${notificationId}/visualized`);
    }

}