<template>
  <div class="full-width full-height">
    <div
      v-if="!isShowConfiguration"
      class="flex row items-center justify-between q-mb-sm notification-header"
    >
      <h6 class="q-py-md">Notificações</h6>
      <div>
        <q-btn
          flat
          rounded
          text-color="grey-8"
          no-caps
          unelevated
          dense
          size="80%"
          icon="bolt"
          class="q-mx-sm"
          :to="{ name: 'lighting-test-list' }"
        />
        <q-btn
          flat
          rounded
          text-color="grey-8"
          no-caps
          unelevated
          dense
          @click="openEvents"
          size="80%"
          icon="calendar_month"
          class="q-mx-sm"
        />
        <!--        <q-btn
         flat
         rounded
         text-color="grey-8"
         no-caps
         unelevated
         dense
         size="80%"
         icon="settings"
         @click="openConfiguration"
       />-->
      </div>
    </div>
    <div
      v-else
      class="flex row items-center justify-start q-mb-sm notification-header"
    >
      <q-icon
        name="chevron_left"
        style="cursor: pointer"
        size="sm"
        color="grey-8"
        @click="openConfiguration"
      />
      <h6>Configuração</h6>
    </div>
    <div
      class="flex column items-center notification-body"
      v-if="!isShowConfiguration"
    >
      <div class="data-notification" v-if="showEvents">
        <q-date
          minimal
          v-model="dateCalendar"
          color="default-pink"
          event-color="default-pink"
          @update:model-value="(value) => notificationFilter(value)"
          :events="events"
        />
      </div>
      <div v-for="notification in ListActivitys"  :key="notification.date">

          <NotificationInfoCard
            :key="infoNotification.NotificationId"
            v-for="infoNotification in notification.notifications"
            :notification="infoNotification"
            @publish="(requestData) => visualizedNotification(requestData)"
          />
        
        </div>
      <!-- <q-tab-panels
        style="background: none"
        v-model="dateCalendar"
        animated
        transition-prev="jump-up"
        transition-next="jump-up"
      >
                  <q-tab-panel
          :name="notification.date"
        > 
     </q-tab-panel> 
      </q-tab-panels> -->
    </div>
    <div v-else>
      <ConfigurationToogles />
    </div>
  </div>
</template>

<style></style>

<script>
import ConfigurationToogles from "@/components/ConfigurationToogles.vue";
import NotificationInfoCard from "@/components/Feed/notification/cards/NotificationInfoCard.vue";
import ActivityDayCard from "@/components/Feed/notification/cards/ActivityDayCard.vue";
import TaskProgress from "@/components/TaskProgress.vue";
import NotificationServices from "@/services/NotificationServices";
import { ref, onMounted } from "vue";
import { date } from "quasar";

export default {
  name: "Notication",
  components: {
    ConfigurationToogles,
    NotificationInfoCard,
    ActivityDayCard,
    TaskProgress,
  },
  setup() {
    let showEvents = ref(false);
    let events = ref([]);
    let isShowConfiguration = ref(false);
    let dateCalendar = ref(date.formatDate(Date.now(), "YYYY/MM/DD").toString() );
    let ListActivitys = ref([]);
    // 

    let notificationsCard = ref([]);
    let notificationServices = new NotificationServices();


    async function getNotifications() {
      var notifications = await notificationServices.getNotifications();

      if (notifications.data != "") {
        

        notifications.data.forEach((element) => {
          let dateFormatted = date.formatDate(element.created_at, "YYYY/MM/DD");

          events.value.push(dateFormatted);
          let checksIfDateAdd = notificationsCard.value.filter(
            (notification) => {
              return notification.date == dateFormatted;
            }
          );

          if (checksIfDateAdd.length == 0) {
            let jsonNotification = {
              date: dateFormatted,
              notifications: notifications.data.filter((not) => {
                return (not.created_at = element.created_at);
              }),
            };
            notificationsCard.value.push(jsonNotification);
          }
        });
        notificationFilter(dateCalendar);
        //ListActivitys.value = notificationsCard.value
      }
    }

    function notificationFilter(data){
      ListActivitys.value = notificationsCard.value.filter((element) => {
        return element.date == data
      });
    }

    async function visualizedNotification(id) {
      notificationsCard.value.filter((not) => {
        return (not.notifications = not.notifications.filter((notification) => {
          return notification.notificationid != id;
        }));
      });

      await notificationServices.setVisualizedNotification(id);
    }

    function openEvents() {
      showEvents.value = !showEvents.value;
    }

    function openConfiguration() {
      isShowConfiguration.value = !isShowConfiguration.value;
    }

    onMounted(() => {
      getNotifications();
    });

    return {
      showEvents,
      ListActivitys,
      isShowConfiguration,
      notificationsCard,
      dateCalendar,
      openConfiguration,
      visualizedNotification,
      openEvents,
      events,
      // eventsFn(date) {
      //   const parts = date.split("/");
      //   return parts[2] % 2 === 0;
      // },
      notificationFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.notification-header {
  min-width: 80%;
  background: #fff;
  padding: 2% 4%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 60px;

  h6 {
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    line-height: 0;
  }

  button {
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.notification-body {
  overflow-y: auto;
  height: 89%;
}

.activity-day-card {
  width: 100%;
  margin: 2% 0;
}
/* .top-datapicker  */
.data-notification {
  position: relative;

  &::before {
    content: "";
    height: 10px;
    width: 290px;
    background: var(--q-secundary);
    border-radius: 12px 12px 0 0;
    position: absolute;
    top: -2px;
  }
}
</style>
