<template>
  <div class="">
    <div class="flex items-center justify-center">
      <q-avatar color="black" text-color="white" class="q-mr-md">
        <q-icon
          v-if="avatarLocation == null"
          name="account_circle"
          color="white"
        />
        <q-img
          v-else
          width="100%"
          height="100%"
          :src="url_aws_bucket + avatarLocation"
        />
      </q-avatar>
      <div class="flex column justify-center q-py-md">
        <q-item-label>{{ fullName }}</q-item-label>
        <q-item-label caption>{{ roleName }}</q-item-label>
      </div>
    </div>
  </div>
</template>

<script>
/* UTILS */
import { ENV  } from "@/utils/env";

export default {
  name: "AvatarUser",
  props: {
    avatarLocation: {
      type: String,
      required: true,
    },
    fullName: {
      type: String,
      required: true,
    },
    roleName: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    /* CONSTANTS */
    const url_aws_bucket =ENV.URL_AWS_BUCKET;

    return {
      /*    CONSTANTS */
      url_aws_bucket,
    };
  },
};
</script>

<style scoped></style>
