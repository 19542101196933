<template>
  <div class="flex column justify-around q-pa-sm q-mb-sm card-content">
   <div>
      <div class="row flex items-center justify-between">
        <h6>{{ notification.type }}</h6>
        <q-btn flat dense round size="sm" icon="close" @click="$emit('publish', notification.notificationid)" color="default-pink"/>
      </div>
      <p>
        {{ notification.content }}
      </p>
      <!-- <small>{{ dateFormatted }}</small> -->
   </div>
  </div>
</template>


<script>
import { date } from "quasar";
export default {
  name: "NotificationInfoCard",
  props:{
    notification: Object
  },
  emits: ['publish'],
  setup(props){
    let dateFormatted = date.formatDate(props.notification.created_at, "DD/MM/YYYY H:mm");
    function closeCard(){
    }
    return {
      dateFormatted,
      closeCard
    }
  }
};
</script>

<style lang="scss" scoped>
.card-content{
  width: 100%;
  border: 1.5px solid #E3E8EB;
  border-radius: 15px;
  position: relative;
  > div{
    width: 95%;
  }
  &::after{
    content: '';
    background: #7CAE44;
    border-radius: 0 15px 15px 0;
    width: 16px;
    height: 100%;
    position: absolute;
    right: 0;
  }
  h6{
    font-style: normal;
    font-weight: bold;
    font-size: .8rem;
    color: #7CAE44;
  }
  P{
    font-style: normal;
    font-weight: normal;
    font-size: .8rem;
    color: #7CAE44;
    line-break: auto;
  }
  small{
    font-style: normal;
    font-weight: 500;
    font-size: 0.6rem;
    color: #7bad4380;
  }
}
</style>