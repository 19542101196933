<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 3.75C0 2.53125 0.984375 1.5 2.25 1.5H21.75C22.9688 1.5 24 2.53125 24 3.75V20.25C24 21.5156 22.9688 22.5 21.75 22.5H2.25C0.984375 22.5 0 21.5156 0 20.25V3.75ZM22.125 14.25V9.75H9.23438V14.25H22.125ZM22.125 16.125H9.23438V20.625H21.5625C21.8438 20.625 22.125 20.3906 22.125 20.0625V16.125ZM1.875 9.75V14.25H7.35938V9.75H1.875ZM9.23438 7.875H22.125V3.9375C22.125 3.65625 21.8438 3.375 21.5625 3.375H9.23438V7.875ZM7.35938 3.375H2.4375C2.10938 3.375 1.875 3.65625 1.875 3.9375V7.875H7.35938V3.375ZM1.875 16.125V20.0625C1.875 20.3906 2.10938 20.625 2.4375 20.625H7.35938V16.125H1.875Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: "Survey",
  props: {
    width: {
      type: Number,
      default: 20,
    },
    height: {
      type: Number,
      default: 18,
    },
    color: {
      type: String,
      default: "var(--q-primary, #fe4e64)",
    },
  },
};
</script>

<style></style>
