<template>
  <q-dialog full-height persistent v-model="isShowDialog">
    <q-card style="width: 50vw">
      <q-tabs
        v-model="tab"
        narrow-indicator
        active-color="white"
        class="bg-default-pink text-white"
        @update:model-value="resetValue()"
      >
        <q-tab name="add" label="Adicionar" />
        <q-tab name="edit" label="Editar" :disable="tab != 'edit'" />
      </q-tabs>
      <q-separator />

      <q-card-section class="scroll">
        <q-form
          ref="myForm"
          @submit="
            () => {
              onSubmit() ? $emit('saveNewCategory', categoryForm) : '';
            }
          "
        >
          <div class="col-12">
            <label class="title-form">Capa *</label>
          </div>
          <div class="col-12 thumb-training q-mt-sm shadow-3">
            <q-img
              height="150px"
              :src="imageUrl"
              fit="contain"
              style="background-color: #e5e5e5"
            />
          </div>
          <div class="col-12 btn-thumb-training flex justify-between q-mt-md">
            <q-btn
              @click="sendEventAddImage"
              class="q-mt-xs"
              unelevated
              color="default-pink"
              text-color="default-white"
              label="Escolher imagem"
            />
            <input
              style="display: none"
              ref="refFileInput"
              @change="onEventFilePicked"
              type="file"
              name="upload"
              accept="image/*"
            />
            <q-btn
              @click="sendEventRemoveImage"
              class="q-mt-xs"
              outline
              color="default-pink"
              label="Remover"
            />
          </div>
          <div class="form-input col-6 column">
            <div class="row q-mt-sm">
              <span class="title-input-form"
                >Nome da categoria
                <b title="Obrigatório" style="cursor: pointer">*</b>
              </span>
            </div>
            <div class="row items-center q-mb-sm">
              <q-input
                class="col-6 q-pa-none"
                bg-color="grey-3"
                v-model="categoryForm.newCategoryName"
                color="default-pink"
                dense
                type="text"
                outlined
                :rules="[(val) => !!val || 'O campo é obrigatório.']"
              />
              <div class="col-5 flex items-start justify-end">
                <FormButton
                  :titleButton="tab != 'edit' ? 'Adicionar' : 'Editar'"
                  submit
                  :showIcon="false"
                />
              </div>
            </div>
          </div>
        </q-form>
      </q-card-section>
      <slot name="categoryList"></slot>
      <q-separator />

      <q-card-actions align="right">
        <q-btn
          flat
          label="Fechar"
          color="default-pink"
          @click="$emit('closeDialog')"
          v-close-popup
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
<script>
//----COMPONENTS
import FormButton from "@/components/admin/buttons/FormButton.vue";

//----QUASAR
import { useQuasar } from "quasar";

//----VUEJS
import { ref, watchEffect, watch } from "vue";

export default {
  name: "DialogCategory",
  components: {
    FormButton,
  },
  props: {
    showDialog: Boolean,
    categorySelected: Object,
  },
  emits: ["saveNewCategory", "closeDialog"],
  setup(props) {
    //----CONSTANTS
    const $q = useQuasar();
    const myForm = ref(null);
    const refFileInput = ref(null);
    const isShowDialog = ref(props.showDialog);

    //----VARIABLES
    let categoryForm = ref({
      newCategoryName: "",
      file: null,
      idCategory: 0,
    });
    let imageUrl = ref("");
    let tab = ref("add");

    watchEffect(() => _updateName());

    watch(
      () => props.showDialog,
      (newState) => {
        isShowDialog.value = newState;
      }
    );

    function _updateName() {
      if (props.categorySelected.label != "") {
        tab.value = "edit";
        categoryForm.value.newCategoryName = props.categorySelected.label;
        imageUrl.value = props.categorySelected.image;
        categoryForm.value.idCategory = props.categorySelected.id;
      }
    }

    function resetValue() {
      categoryForm.value.newCategoryName = "";
      categoryForm.value.file = "";
      categoryForm.value.idCategory = 0;
      imageUrl.value = "";
    }

    function sendEventAddImage() {
      refFileInput.value.click();
    }

    function sendEventRemoveImage() {
      imageUrl.value = "";
      refFileInput.value = null;
      categoryForm.value.file = "";
    }

    function onEventFilePicked(event) {
      const files = event.target.files;
      categoryForm.value.file = files[0];
      const filename = files[0].name;
      if (filename.lastIndexOf(".") <= 0) {
        return alert("Por favor adicione um arquivo válido");
      }
      const fileReader = new FileReader();
      fileReader.addEventListener("load", (event) => {
        imageUrl.value = fileReader.result;
      });
      fileReader.readAsDataURL(files[0]);
    }

    async function onSubmit() {
      if (!(await myForm.value.validate())) {
        $q.notify({
          color: "red-5",
          message: "Você precisa preencher todos os campos obrigatórios",
        });
        return false;
      } else {
        return true;
      }
    }

    return {
      //----CONSTANTS
      myForm,
      refFileInput,
      isShowDialog,
      //----VARIABLES
      categoryForm,
      imageUrl,
      tab,

      //----FUNCTION
      sendEventAddImage,
      sendEventRemoveImage,
      onEventFilePicked,
      onSubmit,
      resetValue,
    };
  },
};
</script>

<style lang="scss" scoped>
.tab-edit {
  display: flex;
  flex: 1;
  font-size: 14px;
  line-height: 1.715em;
  font-weight: 500;
}
.active-tab {
}
</style>
