<template>
  <q-dialog v-model="isShowDialog" persistent>
    <q-card style="max-width: 70vw" class="flex column q-pb-md">
      <q-bar>
        <div class="text-weight-bold">Estatísticas do post</div>
        <q-space />
        <q-btn dense flat icon="close" @click="$emit('closeDialog')">
          <q-tooltip class="bg-white text-primary">Fechar</q-tooltip>
        </q-btn>
      </q-bar>
      <q-list class="q-mt-md q-mx-auto">
        <q-item>
          <q-item-section>
            <div class="flex items-baseline">
              <h2 class="text-h6 q-mr-md">Quantidade de visualizações</h2>
              <p class="text-h4">{{ number_total_views }}</p>
            </div>
          </q-item-section>
        </q-item>
        <q-item>
          <q-item-section class="no-margin no-padding">
            <div class="flex column">
              <div class="flex items-baseline">
                <h2 class="text-h6 q-mr-md">Usuários nos grupos</h2>
                <p class="text-h4">
                  {{ number_users_groups }}
                </p>
              </div>
            </div>
          </q-item-section>
        </q-item>
        <q-item>
          <q-item-section>
            <div class="flex column">
              <div class="flex items-baseline">
                <h2 class="text-h6">Visualização por usuário</h2>
                <p class="text-h4 q-mx-md">
                  {{ number_users_views }}
                </p>
              </div>
              <div class="">
                <q-btn-dropdown
                  flat
                  color="default-pink"
                  label="Quem visualizou"
                  size="0.75rem"
                  class="no-margin no-padding"
                  no-caps
                >
                  <q-list dense class="flex column items-start">
                    <q-item
                      v-for="(
                        { role_name, user_name, avatar_location }, index
                      ) in userViewed"
                      :key="index"
                    >
                      <q-item-section>
                        <q-item-label>
                          <AvatarUser
                            :avatarLocation="`${avatar_location}`"
                            :fullName="`${user_name}`"
                            :roleName="`${role_name}`"
                          />
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                  </q-list>
                </q-btn-dropdown>
              </div>
              <div class="">
                <q-btn-dropdown
                  flat
                  no-caps
                  color="default-pink"
                  label="Quem não visualizou"
                  size="0.75rem"
                  class="no-margin no-padding"
                >
                  <q-list dense class="flex column items-start">
                    <q-item
                      v-for="(
                        { role_name, user_name, avatar_location }, index
                      ) in userDidnotView"
                      :key="index"
                    >
                      <q-item-section>
                        <q-item-label>
                          <AvatarUser
                            :avatarLocation="`${avatar_location}`"
                            :fullName="`${user_name}`"
                            :roleName="`${role_name}`"
                          />
                        </q-item-label>
                      </q-item-section>
                    </q-item>
                  </q-list>
                </q-btn-dropdown>
              </div>
            </div>
          </q-item-section>
        </q-item>
        <q-item>
          <q-item-section>
            <div class="flex justify-between">
              <div class="flex items-baseline">
                <h2 class="text-h6 q-mr-md">Comentários</h2>
                <p class="text-h4">{{ commentsQuantity || 0 }}</p>
              </div>
              <div class="flex items-baseline">
                <h2 class="text-h6 q-mr-md">Curtidas</h2>
                <p class="text-h4">{{ likes || 0 }}</p>
              </div>
            </div>
          </q-item-section>
        </q-item>
      </q-list>
      <q-btn
        color="default-pink"
        outline
        icon-right="analytics"
        class="self-end q-mr-md"
        v-if="canMakeReport"
        label="Relatório"
        :to="{
          name: 'reports',
          query: { postId: postId },
        }"
      />
      <q-inner-loading
        :showing="loading"
        label="Por favor aguarde..."
        label-class="text-teal"
        label-style="font-size: 1.1em"
      >
        <div class="collumn justify-center q-my-md">
          <div style="height: auto; width: 50px; margin: auto">
            <LoadingIcon />
          </div>
          <label>Por favor aguarde...</label>
        </div>
      </q-inner-loading>
    </q-card>
  </q-dialog>
</template>

<script>
/* VUE */
import { ref, onMounted, watch } from "vue";

/* SERVICES */
import PostDataServices from "@/services/PostDataServices";

/* COMPONENTS */
import AvatarUser from "@/components/shared/other/AvatarUser.vue";
import LoadingIcon from "@/components/LoadingIcon.vue";

//----UTILS
import CanUserAccessClass from "@/utils/CanUserAccessClass";

export default {
  name: "DialogAnalytics",
  components: {
    AvatarUser,
    LoadingIcon,
  },
  props: {
    visibleAnalytics: {
      type: Boolean,
    },
    postId: {
      type: Number,
      required: true,
    },
    commentsQuantity: {
      type: Number,
      required: true,
    },
    likes: {
      type: Number,
      required: true,
    },
    canMakeReport: {
      type: Boolean,
    },
  },
  setup(props) {
    /* VARIABLES */
    let number_users_groups = ref(0);
    let number_users_views = ref(0);
    let number_total_views = ref(0);
    let userViewed = ref([]);
    let userDidnotView = ref([]);
    let canMakeReport = ref();
    let loading = ref(false);

    /* CONSTANTS */
    const postDataServices = new PostDataServices();
    const canUserAccessClass = new CanUserAccessClass();

    async function getViews() {
      loading.value = true;
      await postDataServices
        .getViewsById(props.postId)
        .then((response) => {
          let usersJSON = JSON.parse(response.data.users);

          number_total_views.value = response.data.number_total_views;
          number_users_groups.value = response.data.number_users_groups;
          number_users_views.value = response.data.number_users_views;
          usersJSON.forEach((user) => {
            user.visualized
              ? userViewed.value.push(user)
              : userDidnotView.value.push(user);
          });
          loading.value = false;
        })
        .catch((error) => {
          loading.value = false;
          console.log(error);
        });
    }

    function validateActionsPage(routeName) {
      if (!canUserAccessClass.canUserAccess(routeName, false)) {
        return false;
      }
      return true;
    }

    onMounted(() => {
      getViews();
      canMakeReport.value = validateActionsPage("analytic-reports");
    });

    let isShowDialog = ref(props.visibleAnalytics);

    watch(
      () => props.visibleAnalytics,
      (newState) => {
        isShowDialog.value = newState;
      }
    );

    return {
      /* VARIABLES */
      userViewed,
      userDidnotView,
      number_total_views,
      number_users_groups,
      number_users_views,
      validateActionsPage,
      canMakeReport,
      loading,
      isShowDialog,
    };
  },
  emits: ["closeDialog"],
};
</script>

<style scoped></style>
