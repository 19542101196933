<template>
  <div class="col-12 full-width">
    <header class="col-12 row q-px-sm">
      <div class="profile-image flex col-2 items-center">
        <UserPhoto :idUser="userData.id" :avatarUser="userData.avatar_location" />
       
      </div>
      <div class="flex q-px-sm col-8">
        <q-input
          class="flex full-width"
          rounded
          outlined
          borderless
          color="default-pink"
          v-model="newcoment"
          dense
          placeholder="Escreva uma resposta..."
        />
      </div>
      <div class="flex items-center col-2 box-send-icon">
        <q-img
          class="send-message-chat"
          @click="addComent(comentsUsers, newcoment)"
          :src="arrowImg"
        />
      </div>
    </header>

    <main class="q-px-sm">
      <div class="q-pa-md q-gutter-sm">
        <q-tree
          :nodes="comentsUsers"
          node-key="commentId"
          accordion
          no-connectors
          default-expand-all
          :selected="null"
          no-nodes-label="Nenhum comentário encontrado"
          v-model:expanded="expandedKeys"
        >
          <template v-slot:default-header="prop">
            <div class="column full-width">
              <div class="row items-start full-width coment-body">
                <div
                  class="profile-image"
                  :key="prop.node.commentId || 'share'"
                >
                  <UserPhoto :idUser="prop.node.userId" :avatarUser="prop.node.avatar_location" />
                </div>
                <ComentUserCard
                  :name="prop.node.name"
                  :coment="prop.node.text"
                  :date_at="prop.node.created_at"
                  :idUser="prop.node.userId"
                  @disabled="
                    () => disabledCommentInApi(comentsUsers, prop.node)
                  "
                  @edit="
                    (newComment) =>
                      editCommentInApi(
                        comentsUsers,
                        prop.node.commentId,
                        newComment
                      )
                  "
                >
                </ComentUserCard>
              </div>
              <div class="row col-12 full-width q-pl-md">
                <div class="row col-12 flex">
                  <div class="row col-6 flex">
                    <div class="flex items-center q-mr-md">
                      <label
                        class="text-bold text-default-pink q-pa-xs q-mx-xs"
                        style="font-size: 14px"
                        >{{ prop.node.likes.toString() }}</label
                      >
                      <q-icon
                        color="default-pink"
                        size="1.4rem"
                        style="cursor: pointer"
                        :name="
                          prop.node.isLiked
                            ? 'thumb_up_alt'
                            : 'thumb_up_off_alt'
                        "
                        @click="addLike(prop.node)"
                      />
                    </div>
                    <div
                      class="flex items-center col-3"
                      v-if="
                        prop.node.breadcrumbs
                          ? prop.node.breadcrumbs.length < 3
                          : false
                      "
                    >
                      <div class="cursor-pointer" @click="addAwnser(prop.node)">
                        <label
                          class="text-bold text-default-pink cursor-pointer q-pa-xs q-mx-xs label-coments"
                          style="font-size: 14px"
                          >Comentar</label
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-6 flex justify-end show-coments"
                    v-if="prop.node.answers > 0"
                  >
                    <div
                      class="col-3"
                      @click.prevent="LoadCommentsByAnother(prop.node)"
                    >
                      <label class="text-bold q-px-xs q-mx-xs cursor-pointer"
                        >Carregar respostas
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:default-body="prop">
            <div
              class="row full-width items-baseline justify-around q-px-sm q-mt-sm"
              v-if="prop.node.showAwnserInput"
            >
              <div class="flex q-pa-sm" style="width: 85%">
                <q-input
                  ref="awnserRef"
                  class="flex full-width"
                  rounded
                  outlined
                  borderless
                  color="default-pink"
                  dense
                  v-model="prop.node.inputValue"
                  placeholder="Escreva uma resposta..."
                />
              </div>
              <div class="flex items-center col-2 box-send-icon">
                <q-img
                  class="send-message-chat"
                  @click="addComent(prop.node, prop.node.inputValue)"
                  :src="arrowImg"
                />
              </div>
            </div>
          </template>
        </q-tree>
      </div>
    </main>
    <hr />
    <footer class="q-px-md">
      <q-card style="box-shadow: none; border: none">
        <p @click="MoreCommentsByPost">Carregar mais comentários</p>
        <q-inner-loading
          :showing="loading"
          label="Por favor aguarde..."
          label-class="text-teal"
          label-style="font-size: 1.1em"
        />
      </q-card>
    </footer>
  </div>
</template>

<script>
//----IMAGES
import arrowImg from "../../../../assets/icons/location_arrow.svg";

//----COMPONENTS
import UserPhoto from "@/components/shared/card/UserPhoto.vue";
import ComentUserCard from "@/components/Feed/post/cards/ComentUserCard.vue";
import RoundedBtn from "@/components/shared/buttons/RoundedBtn.vue";

//----UTILS
import { ENV  } from "@/utils/env";
import UpdateXpUser from "@/utils/UpdateXpUser";
import blacklistVerification from "@/utils/companySettings/blacklistVerification";

//----SERVICES
import PostDataServices from "@/services/PostDataServices";

//----VUEJS
import { useStore } from "vuex";
import { onMounted, ref, watchEffect } from "vue";
import { useRouter } from "vue-router";

import { useQuasar } from "quasar";

export default {
  name: "SectionComents",
  components: {
    ComentUserCard,
    RoundedBtn,
    UserPhoto,
  },
  props: {
    coments: Array,
    postId: Number,
    awnser: String,
  },
  setup(props) {
    //----CONSTANTS
    const store = useStore();
    const postDataServices = new PostDataServices();
    const $q = new useQuasar();
    const url_aws_bucket =ENV.URL_AWS_BUCKET;

    // const router = new useRouter();

    //----VARIABLES
    let newcoment = ref("");
    let newAwnser = ref("");
    let awnserRef = ref(null);
    let comentsUsers = ref([]);
    // props.coments
    let loading = ref(false);
    let expandedKeys = ref([]);
    let pageNumber = ref(1);
    let userData = ref(store.state.userdata);

    function _setAwnser() {
      newcoment.value = props.awnser;
    }

    function _generateUUIDv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      );
    }

    function generateTemporaryId(comments) {
      comments.forEach((comment) => {
        comment.breadcrumbs = [comment.commentId];
      });
    }

    async function addComent(node, comentContent) {
      if (comentContent == "" || blacklistVerification(comentContent)) {
        return;
      }

      let coment = {
        commentId: 0,
        name: userData.value.first_name + userData.value.last_name,
        avatar_location: userData.value.avatar_location,
        likes: 0,
        answers: 0,
        showAwnserInput: false,
        isLiked: false,
        text: comentContent,
        children: [],
      };

      let commentId = 0;
      if (node.hasOwnProperty("children")) {
        node.children.unshift(coment);
        node.inputValue = "";
        node.showAwnserInput = false;
        expandedKeys.value.push(node.commentId);
        commentId = node.commentId;
      } else {
        node.unshift(coment);
      }

      var newComent = await AddCommentInApi(comentContent, commentId);

      if (node.hasOwnProperty("children")) {
        node.children[0].commentId = newComent.id;
        node.children[0].userId = newComent.user_id;
        node.children[0].breadcrumbs = [newComent.parent_id, newComent.id];
      } else {
        node[0].commentId = newComent.id;
        node[0].userId = newComent.user_id;
        node[0].breadcrumbs = [newComent.id];
      }

      newcoment.value = "";
    }

    function addLike(node) {
      if (!node.isLiked) {
        node.likes = parseInt(node.likes) + 1;
        node.isLiked = true;
        AddLikeInApi("like", node.commentId);
      } else {
        node.likes = parseInt(node.likes) - 1;
        node.isLiked = false;
        AddLikeInApi("deslike", node.commentId);
      }
    }

    function addAwnser(node) {
      expandedKeys.value.push(node.commentId);
      node.showAwnserInput = !node.showAwnserInput;
    }

    function removeComment(node, comments, levelIndex) {
      if (
        levelIndex < node.breadcrumbs.length - 2 &&
        node.breadcrumbs.length - 2 > 0
      ) {
        var index;
        comments.forEach((coment) => {
          if (coment.commentId == node.breadcrumbs[levelIndex]) {
            index = comments.indexOf(coment);
          }
        });

        removeComment(node, comments[index].children, levelIndex + 1);
      } else if (levelIndex == node.breadcrumbs.length - 2) {
        comments.forEach((coment) => {
          if (coment.children.includes(node)) {
            let position = coment.children.indexOf(node);
            coment.children.splice(position, 1);
          }
        });
        return;
      } else {
        let position = comments.indexOf(node);
        comments.splice(position, 1);
        return;
      }
    }

    async function updateProgress() {
      let userdata = store.state.userdata;
      let progress = await UpdateXpUser();

      userdata.progress = progress;

      store.commit("setUserData", userdata);
    }

    async function AddCommentInApi(comentContent, commentId = 0) {
      let postId = props.postId;
      var data = {
        text: comentContent,
      };
      var result;
      await postDataServices
        .addComment(postId, commentId, data)
        .then((response) => {
          result = response.data;
          updateProgress();
        });

      return result;
    }

    async function AddLikeInApi(type, commentId = 0) {
      let postId = props.postId;
      var data = {
        type: type,
        commentId: commentId,
      };
      await postDataServices.LikeOrDeslike(postId, data);
    }

    async function disabledCommentInApi(comments, node) {
      if (node.children.length > 0) {
        $q.notify({
          message: `Não é possivel remover comentários com respostas`,
          color: "red-9",
          position: "bottom",
        });
        return;
      }

      $q.notify({
        message: `Deseja realmente remover este comentario?`,
        color: "red-9",
        position: "bottom",
        actions: [
          {
            label: "Remover",
            color: "white",
            handler: async () => {
              var result = await _disabledCommentInApi(node.commentId);

              if (result) {
                removeComment(node, comments, 0);
              }
            },
          },
        ],
      });
    }

    async function _disabledCommentInApi(commentId) {
      return await postDataServices
        .disabledComment(props.postId, commentId)
        .then((result) => {
          _notification("Comentário removido com sucesso", "green-9");
          return true;
        })
        .catch((elemente) => {
          _notification("Erro ao remover comentário");
          return false;
        });
    }

    async function editCommentInApi(node, commentId, newComment) {
      $q.notify({
        message: `Deseja realmente editar este comentário?`,
        color: "yellow-9",
        position: "bottom",
        actions: [
          {
            label: "Editar",
            color: "white",
            handler: async () => {
              var result = await _editCommentInApi(commentId, newComment);
              if (result) {
                node.forEach((coment) => {
                  if (coment.commentId == commentId) {
                    coment.text = newComment;
                  }
                });
              }
            },
          },
        ],
      });
    }

    async function _editCommentInApi(commentId, newComment) {
      var result;
      var data = {
        text: newComment,
      };
      return await postDataServices
        .editComment(props.postId, commentId, data)
        .then((result) => {
          _notification("Comentário editado com sucesso", "green-9");
          return true;
        })
        .catch((elemente) => {
          _notification("Erro ao editar comentário");
          return false;
        });
    }

    async function MoreCommentsByPost() {
      loading.value = true;
      let postId = props.postId;
      let pageSize = 10;
      var result = await postDataServices
        .getCommentsByPost(postId, pageNumber.value, pageSize)
        .then((result) => {
          result.data.forEach((element) => {
            element.breadcrumbs = [element.commentId];
            comentsUsers.value.push(element);
            expandedKeys.value.push(element.commentId);
          });
          pageNumber.value = pageNumber.value + 1;
        });

      loading.value = false;
    }

    async function LoadCommentsByAnother(node) {
      let postId = props.postId;
      let commentId = node.commentId;

      node.children = [];
      var result = await postDataServices.getCommentsAwnsers(postId, commentId);

      result.data.forEach((element) => {
        element.breadcrumbs = [...node.breadcrumbs, element.commentId];
        node.children.push(element);
      });
      expandedKeys.value.push(node.commentId);
    }

    watchEffect(() => _setAwnser());

    function _notification(message, color = "red-9") {
      $q.notify({
        message: `${message}`,
        color: color,
        position: "bottom",
      });
    }

    onMounted(() => {
      MoreCommentsByPost();
      generateTemporaryId(props.coments);
      userData.value = store.state.userdata;
    });

    return {
      //----IMAEGS
      arrowImg,

      comentsUsers,
      newcoment,
      newAwnser,
      awnserRef,
      userData,
      expandedKeys,
      loading,
      url_aws_bucket,
      disabledCommentInApi,
      editCommentInApi,
      addComent,
      addLike,
      MoreCommentsByPost,
      LoadCommentsByAnother,
      addAwnser,
      blacklistVerification,
    };
  },
};
</script>

<style lang="scss" scoped>
.profile-image {
  //overflow: hidden;
  height: 40px;
  width: 40px;
  padding: 3px;
  border-radius: 30px;
  position: relative;
  .avatar {
    height: 95%;
    max-width: 95%;
    border-radius: 100%;
    position: absolute;
    left: 1.5%;
    top: 2.5%;
  }
}
.label-coments {
  display: none;
  @media (min-width: 800px) {
    display: inline;
  }
}

.show-coments {
  label {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #00000073;
  }
}
.box-send-icon {
  width: 25px;
  height: 25px;
  position: relative;
}
.send-message-chat {
  cursor: pointer;
  width: 100%;
  height: auto;
  position: absolute;
  top: 30%;
}
/* .actions-coment {
  position: absolute;
  width: 100%;
  bottom: -12%;
  left: 12%;
  height: 30px;
} */
hr {
  width: 100%;
  height: 1px;
  min-height: 1px;
  background: #e5e5e5;
  border: none;
  margin-bottom: 0.5rem;
}
main {
  .coment-body {
    cursor: default;
  }
  .root {
    border-bottom: 0.5px solid #e5e5e5;
  }
}

footer {
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 0.81rem;
    line-height: 16px;
    color: #0000004d;
    cursor: pointer;
  }
}
</style>
