<template>
  <div class="container-survey">
    <q-inner-loading color="default-pink" :showing="loading"> </q-inner-loading>
    <h2 v-show="!loading">{{ yourQuestion }}</h2>
    <ul class="container-options" :id="`post-${postId}`">
      <li
        class="optionSurvey"
        ref="optionsListRef"
        v-for="(optionSurvey, index) in optionsSurvey"
        :key="optionSurvey.id"
        @click="answerSurvey(optionSurvey.id, index)"
      >
        {{ optionSurvey.descripition }}
        <span class="percentage" ref="percentageListRef">0%</span>
      </li>
    </ul>
    <small class="expires">{{ expiresDate }}</small>
  </div>
</template>

<script>
/* Vue */
import { ref, onMounted, computed } from "vue";
/* Services */
import PostDataServices from "@/services/PostDataServices";
/* Quasar */
import { useQuasar, date } from "quasar";

export default {
  props: {
    postId: { type: Number, required: true },
    yourQuestion: {
      type: String,
      required: true,
    },
    duration: {
      type: String,
      required: true,
    },
  },
  name: "AnswersSurvey",
  setup(props) {
    /* Services */
    const postDataServices = new PostDataServices();
    /* Quasar */
    const $q = new useQuasar();

    /* Consts */
    const optionsSurvey = ref([]);
    const loading = ref(false);

    /* Functions */
    async function answerSurvey(id, index) {
      const optionsList = document.querySelectorAll(
        `#post-${props.postId} > .optionSurvey`
      );

      const percentageList = document.querySelectorAll(
        `#post-${props.postId} > .optionSurvey > .percentage`
      );

      const dateJs = new Date(props.duration);
      const currentDate = new Date();
      const durationFormattedTotimeStamp = dateJs.getTime();
      const currentTimeStamp = currentDate.getTime();

      if (currentTimeStamp > durationFormattedTotimeStamp) {
        optionsList.forEach((option, index) => {
          option.classList.add("disable");
        });
        $q.notify({
          color: "red-9",
          message: "Você excedeu o prazo para responder essa enquete!",
        });
      } else {
        if (!optionsList.item(index).classList.contains("disable")) {
          optionsList.forEach((option, index) => {
            option.classList.remove("active");
            percentageList.item(index).classList.add("active");
            percentageList.item(index).innerHTML = `${0}%`;
          });
          optionsList.item(index).classList.add("active");

          if (optionsList.item(index).classList.contains("active")) {
            optionsList.forEach((option, index) => {
              option.classList.add("disable");
            });
          }

          postDataServices
            .insertPollResponse(id, props.postId)
            .then((answers) => {
              answers.data.forEach((answer, index) => {
                optionsSurvey.value.forEach((option, index) => {
                  if (option.id === answer.answers_id) {
                    percentageList.item(
                      index
                    ).innerHTML = `${answer.percentage.toFixed(1)}%`;
                  }
                });
              });
            })
            .catch((error) => {
              $q.notify({
                color: "red-9",
                message: error.response.data,
              });
            });
        }
      }
    }

    async function getOptions(id) {
      await postDataServices
        .getOptions(id)
        .then((response) => {
          response.data.forEach((option) => {
            optionsSurvey.value.push(option);
          });
          loading.value = false;
        })
        .catch((error) => {
          loading.value = false;
        });
    }

    async function getInformationSurvey(id) {
      const optionsList = document.querySelectorAll(
        `#post-${props.postId} > .optionSurvey`
      );

      const percentageList = document.querySelectorAll(
        `#post-${props.postId} > .optionSurvey > .percentage`
      );

      const dateJs = new Date(props.duration);
      const currentDate = new Date();
      const durationFormattedTotimeStamp = dateJs.getTime();
      const currentTimeStamp = currentDate.getTime();

      if (currentTimeStamp > durationFormattedTotimeStamp) {
        optionsList.forEach((option, index) => {
          option.classList.add("disable");
        });
      }

      await postDataServices
        .getInformationSurvey(id)
        .then((response) => {
          const { answers_id, informationResponse } = response.data[0];

          if (answers_id !== 0) {
            optionsList.forEach((option, index) => {
              option.classList.add("disable");
            });

            optionsSurvey.value.forEach((option, index) => {
              if (option.id === answers_id) {
                optionsList.item(index).classList.add("active");
              }
            });

            percentageList.forEach((percentage, index) => {
              percentage.innerHTML = `0%`;
              percentage.classList.add("active");
            });

            for (let index = 0; index < optionsSurvey.value.length; index++) {
              informationResponse.forEach((infoResponse) => {
                if (optionsSurvey.value[index].id == infoResponse.answers_id) {
                  percentageList.item(
                    index
                  ).innerHTML = `${infoResponse.percentage.toFixed(1)}%`;
                }
              });
            }
          }
          loading.value = false;
        })
        .catch((error) => {
          loading.value = false;
        });
    }

    /* Lifecycles */
    onMounted(() => {
      loading.value = true;
      getOptions(props.postId);
      setTimeout(() => {
        getInformationSurvey(props.postId);
      }, 500);
    });

    const expiresDate = computed(() => {
      return `Prazo de resposta até ${date.formatDate(
        props.duration,
        "DD/MM/YYYY - HH:mm"
      )}`;
    });

    return {
      optionsSurvey,
      answerSurvey,
      loading,
      expiresDate,
    };
  },
};
</script>

<style scoped>
.container-survey {
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  padding: 16px 24px;
}

h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: -0.0156rem;
  color: #666666;
  margin: 0 0 1rem 0;
}

.container-options {
  list-style: none;
  display: block;
}

li {
  padding: 9px 20px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--q-secundary, #fe4e64);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

li.active {
  border-color: var(--q-secundary, #fe4e64);
}

li.disable {
  cursor: not-allowed;
}

li + li {
  margin-top: 24px;
}

.percentage {
  visibility: hidden;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5rem;
  letter-spacing: -0.0156rem;
  color: #666666;
}

.percentage.active {
  visibility: visible;
}

.expires {
  display: block;
  margin-top: 0.625rem;
  color: #666666;
}
</style>
